h1 {
	padding-bottom: 0 !important;
}
h1::before {
	display: none !important;
}
.teaser-slider-item__date {
	background: #9f9587 !important;
}
.teaser-slider-item__time {
	background: #6eb6b2 !important;
}
.teaser-slider-item__time::before {
	border-color: transparent #448884 transparent transparent !important;
}

/* Interaktionslayer */

.micado-ial-item-newsletter__form-button button,
.micado-ial-item-countdown__button,
.micado-ial-item-message__button,
.micado-ial-item-offer__button {
	background: #6eb6b2 !important;
	border: 0 !important;
	border-radius: 8px !important;
	box-shadow: none !important;
	cursor: pointer !important;
	font-size: 18px !important;
	font-weight: 600 !important;
	letter-spacing: 0.1em !important;
	line-height: 1.2 !important;
	min-width: 64px !important;
	padding: 12px 16px !important;
	text-align: center !important;
	text-transform: uppercase !important;
	transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
	width: auto !important;
}
.micado-ial-item-newsletter__form-button:hover button,
.micado-ial-item-countdown__button:hover,
.micado-ial-item-message__button:hover,
.micado-ial-item-offer__button:hover {
	background: #448884 !important;
}
.micado-ial__notification .micado-ial__close,
.micado-ial__message .micado-ial__close {
	background: #f5f1eb !important;
}
.micado-ial__notification .micado-ial__close .micado-svg-icon svg path,
.micado-ial__message .micado-ial__close .micado-svg-icon svg path {
	fill: #575654 !important;
}
.micado-ial__notification .micado-ial__close:hover,
.micado-ial__message .micado-ial__close:hover {
	background: #9f9587 !important;
}
.micado-ial__notification .micado-ial__close:hover .micado-svg-icon svg path,
.micado-ial__message .micado-ial__close:hover .micado-svg-icon svg path {
	fill: #ffffff !important;
}
.micado-ial-item-notification__button--is-icon {
	background: #6eb6b2 !important;
	border-radius: 8px !important;
}
.micado-ial-item-notification__button--is-icon:hover {
	background: #448884 !important;
}
.micado-ial__close,
.micado-overlay__close {
	background: #9f9587 !important;
}
.micado-ial__close svg path,
.micado-overlay__close svg path {
	fill: #ffffff !important;
}
.micado-ial__close:hover,
.micado-overlay__close:hover {
	background: #575654 !important;
	color: #ffffff !important;
}
.micado-ial__close:hover svg path,
.micado-overlay__close:hover svg path {
	fill: #ffffff !important;
}
.micado-ial-item-contact__headline,
.micado-ial-item-countdown__headline,
.micado-ial-item-message__headline,
.micado-ial-item-newsletter__headline {
	color: #9f9587 !important;
	font-size: 32px !important;
}
.micado-ial-item-countdown__box-value {
	color: #9f9587 !important;
	font-size: 28px !important;
}
.micado-ial-item-countdown__box-label {
	font-size: 14px !important;
}
.micado-ial-item-contact__headline,
.micado-ial-item-newsletter__headline {
	text-align: left !important;
}
.micado-ial-item-contact__text,
.micado-ial-item-newsletter__text {
	text-align: left !important;
}
.micado-ial-item-message__buttons {
	justify-content: flex-end !important;
}
.micado-ial-item-offer__headline {
	text-align: center !important;
}
